import React from 'react';

import AgendaEvent from './AgendaEvent';
import AgendaTab from './AgendaTab';

import { currentMonthAndYearString } from '../helpers';


export default ({
  events,
  handleAgendaTabClick,
  handleCreateUserEvent,
  handleDestroyUserEvent,
  handleOpenNewEventForm,
  handleOpenModal,
  selectedAgendaTab
}) => {
  return (
    <div className='dash card card-margin-bottom about-card'>
      <div className='card-body'>
        <div className='row'>
          <div className='col-xl-12'>
            <div className='mb-1'>
              <div className='d-flex justify-content-between'>
                <button
                  className='btn btn-link pl-0 f-13'
                  onClick={handleOpenModal}
                >
                  See Full Calendar
                </button>
                <button
                  className='btn btn-primary rounded-pill f-10 px-3 font-weight-bold'
                  onClick={handleOpenNewEventForm}
                >
                  <i className="fa fa-plus"></i> Add Events
                </button>
              </div>

              <div className='d-flex justify-content-center border-bottom mt-3'>
                <AgendaTab
                  selected={selectedAgendaTab === 'allEvents'}
                  handleClick={handleAgendaTabClick('allEvents')}
                >
                  All Events
                </AgendaTab>
                <AgendaTab
                  selected={selectedAgendaTab === 'yourEvents'}
                  handleClick={handleAgendaTabClick('yourEvents')}
                >
                  Your Events
                </AgendaTab>
              </div>

              <ol className='list-unstyled'>
                { events.length ? (
                  events.map(event =>
                    <li key={event.id} className='d-flex'>
                      <AgendaEvent
                        {...event}
                        handleCreateUserEvent={handleCreateUserEvent(event.id)}
                        handleDestroyUserEvent={handleDestroyUserEvent(event.user_calendar_event_id)}
                      />
                    </li>
                  )) : (
                    <p className='my-3'>
                      No Events found.
                    </p>
                  )
                }
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
