/*
  This file is intended to hold functions and constants needed to fetch json
  from the server.

  Components can import functions, e.g. `import { fetchEvents } from '../api';`
*/

export const fetchEvents = ({ period = 'month', fromDate, setEventsFunction }) => {
  return fetch(`/calendar_events?start_date=${fromDate}&period=${period}`, {
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow'
  }).then(res => res.json())
  .then(res => setEventsFunction(res))
  .catch(err => []); // TODO: handle error better than silent failure
};

export const createEvent = eventJSON => {
  const token = document.getElementsByName('csrf-token')[0].content;
  const url = '/calendar_events';

  return fetch(url, {
    body: eventJSON,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': token,
    },
    method: 'POST',
    redirect: 'follow'
  });
};

export const updateEvent = eventJSON => {
  const id = JSON.parse(eventJSON)?.calendar_event?.id;
  const token = document.getElementsByName('csrf-token')[0].content;
  const url = `/calendar_events/${id}`;

  return fetch(url, {
    body: eventJSON,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': token,
    },
    method: 'PATCH',
    redirect: 'follow'
  });
};

export const createUserEvent = calendarEventId => {
  const url = `/calendar_events/${calendarEventId}/user_calendar_events`;
  return fetch(url, {
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      // 'X-CSRF-Token':
    },
    method: 'POST',
    redirect: 'follow'
  });
};

export const destroyUserEvent = userEventId => {
  const url = `/user_calendar_events/${userEventId}`;
  return fetch(url, {
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    redirect: 'follow'
  });
};

export const destroyEvent = eventId => {
  const url = `/calendar_events/${eventId}`;
  return fetch(url, {
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    redirect: 'follow'
  });
};

export const getTags = query => {
  return fetch(`/tags?query=${query}`, {
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow'
  });
};
