import React from 'react';
import moment from 'moment';

import EventListItem from './EventListItem';

function todayOrDate(date) {
  const momentDate = moment(date);
  if (momentDate.isSame(new Date(), 'day')) {
    return 'Today';
  } else {
    return momentDate.format('MMM D');
  }
}

export default function EventList({
  date,
  events,
  handleCreateUserEvent,
  handleDestroyUserEvent,
  handleDestroyEventClick,
  handleEditEventClick,
}) {
  return (
    <div className='row my-4'>
      <div className='col-12 col-lg-2 calendar-event-list-date f-17 font-weight-bold'>
        {todayOrDate(date)}
      </div>
      <div className='col-12 col-lg-10'>
        {!events.length ? (
          <p>There are no events scheduled today.</p>
        ) : (
          <ol className='list-unstyled'>
            {events.map(event =>
              <EventListItem
                {...event}
                key={event.id}
                handleCreateUserEvent={handleCreateUserEvent(event.id)}
                handleDestroyUserEvent={handleDestroyUserEvent(event.user_calendar_event_id)}
                handleDestroyEventClick={handleDestroyEventClick(event.id)}
                handleEditEventClick={handleEditEventClick(event.id)}
              />
            )}
          </ol>
        )}
      </div>
    </div>
  );
}
