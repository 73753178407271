import * as tus from "tus-js-client";
// const BASE_URL = 'https://reticulum-staging.herokuapp.com'
// const BASE_URL = 'https://reticulum-stagging.herokuapp.com'
const BASE_URL = 'https://www.ubcreticulum.com/'
// const BASE_URL = 'http://localhost:3000'

$(document).on('turbolinks:load', function () {
    const projectInput = document.querySelector(".videoTusUploadInput");
    const videoSubmitButton = document.querySelector(".videoTusSubmitBtn");


    if (!projectInput) return

    projectInput.addEventListener("input", function() {
        const file = this.files[0]
        const fileTypeVideo = file.type.includes('video')
        const progressBar     = document.querySelector(".video-progress-bar");
        const progressPercentageText = document.querySelector(".progress-percentage");
        let startTime = new Date().getTime();


        if (fileTypeVideo) {
            const endpoint = `${BASE_URL}/video_upload`
              const options = {
                endpoint: endpoint,
                chunkSize: 8 * 1024 * 1024,
                uploadSize: file.size,
                metadata: {
                  fileSize: file.size
                },
                onError: function(error) {
                    console.log("Failed because: " + error)
                },
                onProgress: function(bytesUploaded, bytesTotal) {
                    var progress = bytesUploaded / bytesTotal;
                    var percentage = (bytesUploaded / bytesTotal * 100).toFixed(0);

                    var timeSpent = new Date().getTime() - startTime;
                    var secondsRemaining = Math.round(((timeSpent / progress) - timeSpent) / 1000);
                    var secondsRemaining = isFinite(secondsRemaining) ? secondsRemaining : 0.0;
                    

                    progressBar.style.width = percentage + "%";
                    progressPercentageText.innerHTML = percentage + "% " + "Time Remaining: " + (new Date(secondsRemaining * 1000).toISOString().substr(11, 8))

                    console.log(bytesUploaded, bytesTotal, percentage + "%")
                },
                onSuccess: function() {
                    console.log("Download %s from %s", upload.file.name, upload.url)
                    videoSubmitButton.disabled = false;
                    projectInput.disabled = true
                    
                    // create a webhook
                    // const stream_id = upload.url.substring(upload.url.lastIndexOf("/tus") + 5, upload.url.lastIndexOf("?tus"))
                    // postWebhook(stream_id);

                    // enable sumit button

                }
            }

            const upload = new tus.Upload(file, options)
            upload.start()
        } else {
            console.log("not a video")
        }

    });

    const postWebhook = (stream_id) => {
        $.ajax({
            url: `/video_upload_webhook?stream_id=${stream_id}`,
            type: "GET",
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
            },
            success: function () {
                console.log("from video upload webhook")
            }
        });
    }






});


// var locumId = $(this).data("locum-id");
//         $.ajax({
//             url: "/finding_locums/" + locumId,
//             type: "PATCH",
//             beforeSend: function (xhr) {
//                 xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
//             },
//             data: {
//                 finding_locum: {
//                     from_date: picker.startDate.format("DD/MM/YYYY"),
//                     to_date: picker.endDate.format("DD/MM/YYYY")
//                 }
//             },
//             success: function () {
//                 console.log("complete")
//             }
//         });
//     });