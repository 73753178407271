import React, { useState } from 'react';
import { truncate } from '../helpers';

import moment from 'moment';

const eventTimes = (all_day, event_start, event_end) =>
    all_day ? 'All Day' : `${event_start} - ${event_end}`;

export default props => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className='d-flex mx-2 my-3 w-100 f-11'>
      <div className='flex-column align-items-center f-17 font-weight-bold mr-3'>
        {moment(props.event_date).format('MMM')}
        <br />
        {moment(props.event_date).format('D')}
      </div>
      <div className='d-flex flex-column align-items-start calendar-event-details'>
        {/* <div className='f-12 calendar-event-date'>{props.event_start} - {props.event_end}</div> */}
        <div className='f-12 calendar-event-date'>{eventTimes(props.all_day, props.event_start, props.event_end)}</div>
        <div className='calendar-event-title-collapse' onClick={() => setExpanded(!expanded)}>
          <span className='calendar-event-title f-13'>
            {props.title}
            {expanded ? (
              <>
                <i className='fas fa-angle-up font-weight-bold ml-2'></i>
              </>
            ) : (
              <i className='fas fa-angle-down font-weight-bold ml-2'></i>
            )}
          </span>
        </div>
        <div>
          <a href={props.user.link}>
            {props.user.full_name}
          </a>
        </div>
        {expanded ? (
          <>
            <div title={props.description} className='f-12 calendar-event-description'>
              {truncate(props.description)}
            </div>
            <div className='f-12'>
              <a href={props.link} target='_blank'>
                {props.link}
              </a>
            </div>
          </>
        ) : (
          null
        )}
        {props.user_calendar_event_id ? (
          <button className='btn btn-primary f-11 rounded-pill calendar-event-add-to-calendar-btn text-nowrap' onClick={props.handleDestroyUserEvent}>
            <i className='fa fa-minus'></i> Don&rsquo;t Send me a reminder
          </button>
        ) : (
          <button className='btn btn-outline-primary f-11 rounded-pill calendar-event-add-to-calendar-btn text-nowrap' onClick={props.handleCreateUserEvent}>
              <i className='fa fa-plus'></i> Send me a reminder
          </button>
        )}
      </div>
    </div>
  );
}
