import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"

import { getTags } from '../api';

const formDefaults = {
  all_day: false,
  recurring: false,
  description: '',
  errors: null,
  event_date: '',
  event_end: '',
  event_start: '',
  id: '',
  link: '',
  tag_ids: [],
  tags: [],
  title: '',
  recurring_events: []
};

const today = new Date()
const tomorrow = new Date()

tomorrow.setDate(tomorrow.getDate())

// const [values, setValues] = useState([today, tomorrow])

export default class EventForm extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {...formDefaults, ...props.event, values: [today, tomorrow], submitBtnDisabled: true};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleTagSelectChange = this.handleTagSelectChange.bind(this);
    this.handleRecurringDateChange = this.handleRecurringDateChange.bind(this);
  }

  render() {
    const defaultTags = this.state.tags.map(({ id, name }) => ({ label: name, value: id }));

    return (
      
      <>
        {/* <div
          className='calendar-event-form-overlay'
          onClick={this.props.handleCloseForm}
        >
        </div> */}
        
        <div className='calendar-event-form'>

          

          <b className='f-17 font-weight-bold mb-3'>Add to Calendar</b>

          <form noValidate onSubmit={this.handleSubmit}>
            <div className='form-group'>
              <label htmlFor='event_title'>Name of the meeting</label> <span class="fieldRequired">*</span>
              <input
                className='form-control'
                id='event_title'
                name='title'
                onChange={this.handleChange}
                placeholder='Type name'
                ref={element => { this.titleInput = element }}
                required
                type='text'
                value={this.state.title}
              />
            </div>

            <label htmlFor='event_event_date'>Date</label> <span class="fieldRequired">*</span>
            <div className='row'>
              <div className='form-group col'>
                <input
                  className='form-control'
                  id='event_event_date'
                  name='event_date'
                  onChange={this.handleChange}
                  placeholder={moment(new Date()).format('MMM D, YYYY')}
                  required
                  type='date'
                  value={this.state.event_date}
                  size={5}
                />
              </div>
              <div className='form-group col-2 align-self-center'>
                <div className='form-check'>
                  <input
                    checked={this.state.all_day}
                    className='form-check-input'
                    id='event_all_day'
                    name='all_day'
                    onChange={this.handleChange}
                    type='checkbox'
                  />
                  <label className='form-check-label' htmlFor='event_all_day'>
                    All day
                  </label>
                </div>
              </div>

              <div className='form-group col-3 align-self-center'>
                <div className='form-check'>
                  <input
                    checked={this.state.recurring}
                    className='form-check-input'
                    id='event_recurring'
                    name='recurring'
                    onChange={this.handleChange}
                    type='checkbox'
                  />
                  <label className='form-check-label' htmlFor='event_recurring'>
                    Recurring
                  </label>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='form-group col'>
                <label htmlFor='event_event_start'>
                  Start time
                </label> <span class="fieldRequired">*</span>
                <input
                  className='form-control'
                  disabled={this.state.all_day}
                  id='event_event_start'
                  name='event_start'
                  onChange={this.handleChange}
                  placeholder='12:00pm'
                  required={!this.state.all_day}
                  type='time'
                  value={this.state.event_start}
                  size={5}
                />
              </div>
              <div className='form-group col'>
                <label htmlFor='event_event_end'>
                  End time
                </label> <span class="fieldRequired">*</span>
                <input
                  className='form-control'
                  disabled={this.state.all_day}
                  id='event_event_end'
                  name='event_end'
                  onChange={this.handleChange}
                  placeholder='12:00pm'
                  required={!this.state.all_day}
                  type='time'
                  value={this.state.event_end}
                  size={5}
                />
              </div>
            </div>

            <div className='row'>
              <div className='form-group col-12'>
                <label htmlFor='recurring_event_dates' className="display-block">
                  Recurring Dates
                </label>
                <DatePicker
                  multiple
                  plugins={[
                    <DatePanel />
                  ]}
                  value={this.state.value}
                  onChange={this.handleRecurringDateChange}
                  className='form-control'
                  disabled={!this.state.recurring}
                />
              </div>
            </div>

            <div className='form-group'>
              <label htmlFor='event_link'>Link</label>
              <input
                className='form-control'
                id='event_link'
                name='link'
                onChange={this.handleChange}
                placeholder='https://'
                type='text'
                value={this.state.link}
              />
            </div>

            <div className='form-group'>
              <label htmlFor='event_description'>Description</label> <span class="fieldRequired">*</span>
              <textarea
                className='form-control'
                id='event_description'
                name='description'
                onChange={this.handleChange}
                placeholder='Type a short description of your event'
                required
                rows={4}
                type='text'
                value={this.state.description}
              />
            </div>

            <div className='form-group'>
              <label htmlFor='event_tag_ids'>Please select one or more appropriate topic tags</label> <span class="fieldRequired">*</span>
              <AsyncSelect
                id='event_tag_ids'
                isMulti
                cacheOptions
                defaultOptions={true}
                loadOptions={this.tagOptions}
                // name='tag_ids'
                onChange={this.handleTagSelectChange()}
                defaultValue={defaultTags}
                // css-yk16xz-control
              />
            </div>

            {this.state.formErrors &&
              <div>
                <p className='text-danger'>Please ensure all fields are entered to submit!</p>
              </div>
            }

            <div className='actions d-flex justify-content-end'>
              <button
                className='btn btn-primary btn-sm rounded-pill mx-2'
                type='submit'
                disabled={this.state.submitBtnDisabled}
              >
                Submit
                
              </button>
            </div>
          </form>
        </div>
      </>
    )
  }

  componentDidMount() {
    if (this.titleInput) this.titleInput.focus();
  }

  handleChange(event) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({ [event.target.name]: value });
  }

  handleTimeChange(event) {

  }

  handleTagSelectChange() {
    return options => {
      const tagIds = Array.isArray(options) ? options.map(tag => tag.value) : [];
      this.setState({ tag_ids: tagIds });
      this.setState({ submitBtnDisabled: false });
    }
  }

  handleRecurringDateChange(event) {
    const value = event.map(re => re.toDate())
    this.setState({ recurring_events: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    
    if (this.state.recurring && this.props.event === null) {
      var confirmation_result = window.confirm("You've selected the multi event submission, make sure all details are correct. Are you sure you want to continue? \n\n" + `Selected Dates:\n\n${this.state.recurring_events.map(x => moment(x).format('MMM D, YYYY')).join("\n") }` );
    } else {
      var confirmation_result = true 
    }
    
    if (form.checkValidity() && confirmation_result) {
      this.props.handleFormSubmit(this.state); // run submit function passed by props
    } else {
      this.setState({ formErrors: true });
    }
  }

  tagOptions(inputValue) {
    return getTags(inputValue)
    .then(res => res.json())
    .catch(err => {
      console.error(err);
      return []; // silent failure
    });
  }

}
