import React from 'react';

const defaultClassNames = ['calendar-agenda-filter-btn', 'btn', 'btn-link', 'f-13'];

export default ({ children, handleClick, selected }) => {
  const classNames = selected ? (
    [...defaultClassNames].concat(['active'])
  ) : (
    defaultClassNames
  );

  return (
    <button
      className={classNames.join(' ')}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};
