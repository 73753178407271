import React from 'react';

import moment from 'moment';

const formattedTime = (date, time) => moment(`${date}T${time}`).format('LT');

export default function EventListItem({
  all_day,
  description,
  belongs_to_user,
  event_date,
  event_end,
  event_start,
  handleCreateUserEvent,
  handleDestroyUserEvent,
  handleDestroyEventClick,
  handleEditEventClick,
  id,
  link,
  tags,
  title,
  user,
  user_calendar_event_id,
}) {
  return (
    <li key={id} className='mb-4'>
      <div className='d-flex flex-column flex-lg-row'>
        <div className='d-flex flex-column calendar-event-details flex-grow-2'>
          <div className='f-12 calendar-event-date mb-2'>
            {all_day ? (
              'All Day'
            ) : (
              `${formattedTime(event_date, event_start)} - ${formattedTime(event_date, event_end)}`
            )}
          </div>
          <b className='calendar-event-title'>{title}</b>
          <div>
            <a href={user.link} target='_blank'>
              {user.full_name}
            </a>
          </div>
          <div className='calendar-event-description'>
            {description}
          </div>
          {
            link && (
              <p>
                <a href={link} target='_blank'>{link}</a>
              </p>
            )
          }
          <div className='d-flex flex-nowrap justify-content-start'>
            {
              tags.map(({ id, name }) => (
                <button key={id} className='btn btn-outline-primary rounded-pill py-0 ml-0 mr-4'>
                  {name}
                </button>
              ))
            }
          </div>
          <hr />
        </div>
        <div className='w-100 d-flex align-items-lg-start justify-content-lg-end mt-1 mt-lg-3'>
          {user_calendar_event_id ? (
            <button className='btn btn-primary f-11 rounded-pill text-nowrap' onClick={handleDestroyUserEvent}>
              <i className='fa fa-minus'></i> Don&rsquo;t Send me a reminder
            </button>
          ) : (
            <button className='btn btn-outline-primary f-11 rounded-pill text-nowrap' onClick={handleCreateUserEvent}>
                <i className='fa fa-plus'></i> Send me a reminder
            </button>
          )}
          {belongs_to_user && (
            <>
            <button
              className='btn btn-outline-primary f-11 rounded-pill mx-lg-1 text-nowrap'
              onClick={handleEditEventClick}
            >
              Edit
            </button>
            <button
              className='btn btn-outline-danger f-11 rounded-pill mx-lg-1 text-nowrap'
              data-confirm='Delete this event?'
              onClick={handleDestroyEventClick}
            >
              Delete
            </button>
            </>
          )}
        </div>
      </div>
    </li>
  )
}
