import React from 'react';
import ReactDOM from 'react-dom';
import ReticulumCalendar from './components/ReticulumCalendar';

document.addEventListener('turbolinks:load', function(_event) {
  const element = document.getElementById('reticulum-calendar')

  if (element) {
    ReactDOM.render(
      <ReticulumCalendar />,
      element
    );
  }
});
