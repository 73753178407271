import React, { Component } from 'react';

import ReactModal from 'react-modal';
import Calendar from 'react-calendar';
import Agenda from './Agenda';
import EventForm from './EventForm';
import EventList from './EventList';
import TileEventList from './TileEventList';

import moment from 'moment';

import {
  createEvent,
  fetchEvents,
  destroyEvent,
  updateEvent,
  createUserEvent,
  destroyUserEvent,
} from '../api';

import { areSameDay } from '../helpers'; // TODO: replace with moment.js

export default class ReticulumCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agendaEvents: [],
      calendarEvents: [],
      date: new Date(),
      formEvent: null,
      showForm: false,
      isModalOpen: false,
      selectedAgendaTab: 'allEvents', // one of: ['allEvents', 'yourEvents']
    };
    this.handleActiveStartDateChange = this.handleActiveStartDateChange.bind(this);
    this.handleAgendaTabClick = this.handleAgendaTabClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCreateUserEvent = this.handleCreateUserEvent.bind(this);
    this.handleDestroyUserEvent = this.handleDestroyUserEvent.bind(this);
    this.handleDestroyEventClick = this.handleDestroyEventClick.bind(this);
    this.handleEditEventClick = this.handleEditEventClick.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOpenNewEventForm = this.handleOpenNewEventForm.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.tileContent = this.tileContent.bind(this);
    this.closeFormWindow = this.closeFormWindow.bind(this);
    this.submitEventUpdate = this.submitEventUpdate.bind(this);
    this.submitNewEvent = this.submitNewEvent.bind(this);
  }

  render() {
    return (
      <>
        <Agenda
          events={this.eventsForAgenda()}
          handleAgendaTabClick={this.handleAgendaTabClick}
          handleCreateUserEvent={this.handleCreateUserEvent}
          handleDestroyUserEvent={this.handleDestroyUserEvent}
          handleOpenNewEventForm={this.handleOpenNewEventForm}
          handleOpenModal={this.handleOpenModal}
          selectedAgendaTab={this.state.selectedAgendaTab}
        />

        <ReactModal
          appElement={document.getElementById('reticulum-calendar')}
          isOpen={this.state.isModalOpen}
          onRequestClose={() => this.setState({ isModalOpen: false })}
          className='calendar-modal'
          bodyOpenClassName='modal-open'
        >
          {/* <div
            className={this.state.showForm ? 'calendar-event-form-overlay' : null}
            onClick={this.props.handleCloseForm}
          >
          </div> */}

          <button
            type='button'
            className='close'
            aria-label='Close'
            onClick={() => this.setState({ isModalOpen: false })}
          >
            <span aria-hidden='true'>&times;</span>
          </button>

          <div className='calendar-form-container my-2'>
            <button
              className='btn btn-primary f-11 rounded-pill mt-3 text-nowrap'
              dangerouslySetInnerHTML={this.toggleFormBtnText()}
              onClick={() => { this.setState({ formEvent: null, showForm: !this.state.showForm }) }}
              style={{ zIndex: 1000 }}
            />

            {this.state.showForm ? (
              <EventForm
                event={this.state.formEvent}
                handleFormSubmit={this.handleFormSubmit}
                handleCloseForm={this.closeFormWindow}
              />
            ) : (
              null
            )}
          </div>

          <Calendar
            calendarType='US'
            minDetail='year'
            onActiveStartDateChange={this.handleActiveStartDateChange}
            onChange={this.handleChange}
            tileContent={calendarData => this.tileContent(calendarData)}
            value={this.state.date}
          />
          <EventList
            date={this.state.date}
            events={this.eventsForSelectedDate(this.state.date)}
            handleCreateUserEvent={this.handleCreateUserEvent}
            handleDestroyUserEvent={this.handleDestroyUserEvent}
            handleDestroyEventClick={this.handleDestroyEventClick}
            handleEditEventClick={this.handleEditEventClick}
          />
        </ReactModal>
      </>
    );
  }

  componentDidMount() {
    const today = moment();
    fetchEvents({
      period: 'month',
      fromDate: moment().format('YYYY-MM-DD'),
      setEventsFunction: events => {
        const agendaEvents = events
          .filter(event => {
            const datetime = `${event.event_date}T${event.event_start}`;
            return moment(datetime).isSameOrAfter(today, 'day');
          })
          .slice(0, 10);
        this.setState({
          agendaEvents: agendaEvents,
          calendarEvents: events,
          date: today.toDate()
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const oldDateMoment = moment(prevState.date);
    const newDateMoment = moment(this.state.date);

    if (oldDateMoment.isSame(newDateMoment, 'month')) return;

    fetchEvents({
      period: 'month',
      fromDate: newDateMoment.format('YYYY-MM-DD'),
      setEventsFunction: events => {
        this.setState({
          calendarEvents: events,
          date: newDateMoment.toDate()
        });
      }
    });
  }

  handleCreateUserEvent(id) {
    return event => {
      createUserEvent(id)
      .then(res => res.json())
      .then(res => {
        const agendaPosition =
          this.state.agendaEvents.findIndex(e => e.id === id);
        const calendarPosition =
          this.state.calendarEvents.findIndex(e => e.id === id);

        if (agendaPosition > -1) {
          this.setState({
            ...this.state,
            agendaEvents: [
              ...this.state.agendaEvents.slice(0, agendaPosition),
              {...res},
              ...this.state.agendaEvents.slice(agendaPosition + 1)
            ]
          });
        }

        if (calendarPosition > -1) {
          this.setState({
            ...this.state,
            calendarEvents: [
              ...this.state.calendarEvents.slice(0, calendarPosition),
              {...res},
              ...this.state.calendarEvents.slice(calendarPosition + 1)
            ]
          });
        }
        return res;
      }).catch(err => {console.error(err)});
    };
  }

  handleDestroyEventClick(id) {
    return event => {
      destroyEvent(id)
      .then(() => {
        const agendaPosition =
          this.state.agendaEvents.findIndex(e => e.id === id);
        const calendarPosition =
          this.state.calendarEvents.findIndex(e => e.id === id);

        this.setState(state => ({
          agendaEvents: agendaPosition < 0 ? (
            state.agendaEvents
          ) : (
            [...state.agendaEvents.slice(0, agendaPosition), ...state.agendaEvents.slice(agendaPosition + 1)]
          ),
          calendarEvents: calendarPosition < 0 ? (
            state.calendarEvents
          ) : (
            [...state.calendarEvents.slice(0, calendarPosition), ...state.calendarEvents.slice(calendarPosition + 1)]
          )
        }));
      }).catch(err => console.error(err));
    };
  }

  handleDestroyUserEvent(id) {
    return event => {
      destroyUserEvent(id)
      .then(res => res.json())
      .then(res => {
        const agendaPosition =
          this.state.agendaEvents.findIndex(e =>
            e.user_calendar_event_id === id
          );
        const calendarPosition =
          this.state.calendarEvents.findIndex(e =>
            e.user_calendar_event_id === id
          );

        this.setState(state => ({
          agendaEvents: agendaPosition < 0 ? (
            state.agendaEvents
          ) : (
            state.agendaEvents.slice(0, agendaPosition),
            {...res},
            state.agendaEvents.slice(agendaPosition + 1)
          ),
          calendarEvents: calendarPosition < 0 ? (
            state.calendarEvents
          ) : (
            state.calendarEvents.slice(0, calendarPosition),
            {...res},
            state.calendarEvents.slice(calendarPosition + 1)
          )
        }));
        return res;
      }).catch(err => {console.error(err)});
    };
  }

  handleActiveStartDateChange({ activeStartDate = new Date(), value, view }) {
    fetchEvents({
      period: 'month',
      fromDate: moment(activeStartDate).format('YYYY-MM-DD'),
      setEventsFunction: events =>
        this.setState({ calendarEvents: events, date: activeStartDate })
    });
  }

  handleAgendaTabClick(selectedTab) {
    return _event => {
      this.setState({ selectedAgendaTab: selectedTab });
    };
  }

  handleChange(value, event) {
    this.setState({ date: value });
  }

  handleFormSubmit(params) {
    const calendarEvent = JSON.stringify({ calendar_event: params });

    if (!!params.id) {
      this.submitEventUpdate(calendarEvent);
    } else {
      this.submitNewEvent(calendarEvent);
    }
  }

  submitEventUpdate(calendarEvent) {
    const id = JSON.parse(calendarEvent)?.calendar_event?.id;
    const agendaPosition = this.state.agendaEvents.findIndex(e => e.id === id);
    const calendarPosition = this.state.calendarEvents.findIndex(e => e.id === id);
    return updateEvent(calendarEvent)
    .then(res => res.json())
    .then(res => {
      this.setState(state => ({
        showForm: false,
        agendaEvents: agendaPosition < 0 ? (
          state.agendaEvents
        ) : (
          [
            ...state.agendaEvents.slice(0, agendaPosition),
            {...res},
            ...state.agendaEvents.slice(agendaPosition + 1)
          ]
        ),
        calendarEvents: calendarPosition < 0 ? (
          state.calendarEvents
        ) : (
          [
            ...state.calendarEvents.slice(0, calendarPosition),
            {...res},
            ...state.calendarEvents.slice(calendarPosition + 1)
          ]
        )
      }));
      return res;
    }).catch(err => {console.error(err)});
  }

  submitNewEvent(calendarEvent) {
    return createEvent(calendarEvent)
    .then(res => res.json())
    .then(res => {
      if (Array.isArray(res)) {
        res.forEach(x => 
          this.setState({
            date: moment(`${x.event_date}T${x.event_start}`).toDate(),
          showForm: false,
          agendaEvents: [
            ...this.state.agendaEvents,
            {...x}
          ],
          calendarEvents: [
            ...this.state.calendarEvents,
            {...x}
          ]
          })
        )
      } else {
        this.setState({
          date: moment(`${res.event_date}T${res.event_start}`).toDate(),
          showForm: false,
          agendaEvents: [
            ...this.state.agendaEvents,
            {...res }
          ],
          calendarEvents: [
            ...this.state.calendarEvents,
            {...res}
          ]
        });
      }

    }).catch(err => console.error(err)); // TODO: handle errors better
  }

  handleOpenModal() {
    this.handleActiveStartDateChange(this.state.date);
    this.setState({ isModalOpen: true })
  }

  handleOpenNewEventForm() {
    this.setState({
      formEvent: null,
      isModalOpen: true,
      showForm: true
    });
  }

  tileContent({ activeStartDate, date, view }) {
    const dateMoment = moment(date);
    const events = this.state.calendarEvents.filter(({ event_date, event_start }) => {
      const eventMoment = moment(event_date + ' ' + event_start);
      return dateMoment.isSame(eventMoment, 'day');
    });

    const isSelectedDate = dateMoment.isSame(this.state.date, 'day');

    return <TileEventList isSelectedDate events={events} />
  }

  eventsForSelectedDate(date) {
    if (typeof date === 'undefined' || date === null) return [];
    // areSameDay returns a function that compares dates
    const isEventOnCalendarDay = areSameDay(date);

    return this.state.calendarEvents.filter(event =>{
      const eventDate = new Date(`${event.event_date}T${event.event_start}`);
      return isEventOnCalendarDay(eventDate)
    });
  }

  eventsForAgenda() {
    if (this.state.selectedAgendaTab === 'yourEvents') {
      return this.state.agendaEvents.filter(e => e.user_calendar_event_id !== null)
    } else {
      return this.state.agendaEvents;
    }
  }

  toggleFormBtnText() {
    return this.state.showForm ? (
      {__html: "<i class='fa fa-times'></i> Close"}
    ) : (
      {__html: "<i class='fa fa-plus'></i> Create new event"}
    );
  }

  closeFormWindow() {
    this.setState({
      showForm: false
    });
  }

  handleEditEventClick(eventId) {
    const calendarEvent = this.state.calendarEvents.find(e => e.id === eventId);
    if (!calendarEvent) return;

    return event => {
      event.preventDefault();
      this.setState({
        formEvent: calendarEvent,
        isModalOpen: true,
        showForm: true
      });
    };
  }
}
