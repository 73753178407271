import React from 'react';

import TileEvent from './TileEvent';

export default ({ events, isSelectedDate }) => {
  if (events.length === 0) return null; // do not render, no events

  if (events.length > 2) {
    events = [events[0], { id: new Date().valueOf(), title: `+ ${events.length - 2} More` }];
  }

  return (
    <>
      <div className='d-none d-lg-block'>
        {events.map(({ id, title }) => (
          <TileEvent key={id}>
            {title}
          </TileEvent>
        ))}
      </div>
      <i
        className={`fas fa-circle d-none-lg ${isSelectedDate ? 'text-primary' : ''}`}
      />
    </>
  );
};
